<!--
程序名：网站登录页面
功能：进入网站的入口
-->
<template>
  <div class="login">
    <el-button type="primary" @click="toAuth" style="text-align: center;width: 150px;margin-top: 40px;">去统一认证系统</el-button>
  </div>
</template>
<script>
export default {
  name: 'Login',
  data() {
    return {
    }
  },
//页面初始化
  mounted() {},
//方法定义
  methods: {
    toAuth() {
      const redirect_uri = encodeURIComponent(this.$g.AUTH_INDEX);
      location.href = `${this.$g.AUTHORIZE_NET}?response_type=code&client_id=${this.$g.CLIENT_ID}&redirect_uri=${redirect_uri}&state=authapi_index`;
    },
    //检查登录是否过期
      logincheck(){
          designOpera({
          opera_type:'logincheck',
        })
        .then(data=>{
          console.log(data);
          if(data.code==404){
            this.$router.push({path:'/login'})
          }
          if(data.data!=null){
            console.log(data)
            sessionStorage.setItem('username',data.data.user) //将后端传的username存入session
            this.$router.push({path:'/paper'})
          }
        })
      },
  }
}
</script>

<style scoped>
  /* 主页面样式 */
  .login {
    background-image: url('../../static/images/bg.svg');
    position: absolute;  /*绝对定位*/
    width:100%;
    height:100%;
  }
</style>
